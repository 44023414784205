<template>
  <div>
    <Header/>
    <div class="wrapper">
      <h1 class="title">Тарифы</h1>
      <p class="description">Выберите подходящий вашем бизнесу тариф</p>

      <section class="tariffs">
        <div class="tariff__item">
          <div class="tariff__item__header">
            <span class="tariff__item__header__title">Оплата за месяц</span>
            <span class="tariff__item__header__price">
              3490
              <span class="tariff__item__header__price__currency"> тг/мес</span>
            </span>
            <span class="tariff__item__header__price__saleless">
              6990 тг/мес
            </span>
          </div>
          <button class="tariff__item__button" @click="goToPayment(1)">
            НАЧАТЬ РАБОТАТЬ
          </button>
          <hr class="tariff__item__separator">
          <span class="tariff__item__text">При выборе данного тарифа Вам будут доступны все преимущества платформы. После окончания выбранного периода за использование сервиса будет начисляться ежемесячная плата.</span>
          <hr class="tariff__item__separator">
          <span class="tariff__item__text">Неограниченное количество студентов, которых Вы можете зарегистрировать на сайте</span>
          <span class="tariff__item__text">Ведение расписания уроков</span>
          <span class="tariff__item__text">Домашние задания и установка сроков окончания приема д/з</span>
          <span class="tariff__item__text">Хранение неограниченного количества учебных материалов</span>
          <button class="tariff__item__button" @click="goToPayment(1)">
            НАЧАТЬ РАБОТАТЬ
          </button>
        </div>

        <div class="tariff__item__popular">
          <div class="popular-item">САМЫЙ ПОПУЛЯРНЫЙ</div>
          <div class="tariff__item__header">
            <span class="tariff__item__header__title">Оплата за 3 месяца</span>
            <span class="tariff__item__header__price">
              10490
              <span class="tariff__item__header__price__currency"> тг/3 мес</span>
            </span>
            <span class="tariff__item__header__price__saleless">
              20970 тг/3 мес
            </span>
          </div>
          <button class="tariff__item__button popular" @click="goToPayment(2)">
            НАЧАТЬ РАБОТАТЬ
          </button>
          <hr class="tariff__item__separator">
          <span class="tariff__item__text">При выборе данного тарифа Вам будут доступны все преимущества платформы. После окончания выбранного периода за использование сервиса будет начисляться ежемесячная плата.</span>
          <hr class="tariff__item__separator">
          <span class="tariff__item__text">Неограниченное количество студентов, которых Вы можете зарегистрировать на сайте</span>
          <span class="tariff__item__text">Ведение расписания уроков</span>
          <span class="tariff__item__text">Домашние задания и установка сроков окончания приема д/з</span>
          <span class="tariff__item__text">Хранение неограниченного количества учебных материалов</span>
          <button class="tariff__item__button popular" @click="goToPayment(2)">
            НАЧАТЬ РАБОТАТЬ
          </button>
        </div>

        <div class="tariff__item">
          <div class="tariff__item__header">
            <span class="tariff__item__header__title">Оплата за год</span>
            <span class="tariff__item__header__price">
              41 880
              <span class="tariff__item__header__price__currency"> тг/год</span>
            </span>
            <span class="tariff__item__header__price__saleless">
              83880 тг/год
            </span>
          </div>
          <button class="tariff__item__button" @click="goToPayment(3)">
            НАЧАТЬ РАБОТАТЬ
          </button>
          <hr class="tariff__item__separator">
          <span class="tariff__item__text">При выборе данного тарифа Вам будут доступны все преимущества платформы. После окончания выбранного периода за использование сервиса будет начисляться ежемесячная плата.</span>
          <hr class="tariff__item__separator">
          <span class="tariff__item__text">Неограниченное количество студентов, которых Вы можете зарегистрировать на сайте</span>
          <span class="tariff__item__text">Ведение расписания уроков</span>
          <span class="tariff__item__text">Домашние задания и установка сроков окончания приема д/з</span>
          <span class="tariff__item__text">Хранение неограниченного количества учебных материалов</span>
          <button class="tariff__item__button" @click="goToPayment(3)">
            НАЧАТЬ РАБОТАТЬ
          </button>
        </div>
      </section>
    </div>
    <Footer/>
    <transition name="modal" mode="out-in">
      <PaymentModal :tariff_id="selectedTariff" v-if="paymentModalIsActive" :hide="() => {paymentModalIsActive = false; selectedTariff = undefined}"/>
    </transition>
    <transition name="modal" mode="out-in">
      <Modal v-if="successPaymentModalIsActive" :hide="() => {successPaymentModalIsActive = false}">
        <div class="modal__body">
            <span>Успешно оплачено</span>
        </div>
      </Modal>
    </transition>
    <transition name="modal" mode="out-in">
      <Modal v-if="failurePaymentModalIsActive" :hide="() => {failurePaymentModalIsActive = false}">
        <div class="modal__body">
            <span>Оплата не прошла</span>
            <span>Попробуйте еще раз</span>
        </div>
      </Modal>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue-demi'
import Footer from '../../components/uiLanding/layouts/Footer.vue'
import Header from '../../components/uiLanding/layouts/Header.vue'
import PaymentModal from '../../components/uiLanding/PaymentModal.vue'
import { useAuth } from '@/modules/auth'
import { useRoute, useRouter } from 'vue-router'
import Modal from '@/components/common/Modal.vue'

const paymentModalIsActive = ref(false)
const selectedTariff = ref()
const { logged } = useAuth()
const router = useRouter()
const route = useRoute()
const successPaymentModalIsActive = ref(route.params.payment_status === 'success')
const failurePaymentModalIsActive = ref(route.params.payment_status === 'failure')

const goToPayment = tariffId => {
  if (logged()) {
    selectedTariff.value = tariffId
    paymentModalIsActive.value = true
  } else {
    router.push('/business-registration')
  }
}
</script>

<style scoped>
h1.title {
  padding: 30px 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.description {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  margin-bottom: 40px;
}
section.tariffs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-end;
  column-gap: 15px;
  margin-bottom: 30px;
}
.tariff__item {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 0.5px solid var(--primary-color);
  /* margin-bottom: 3px; */
}
.tariff__item__popular {
  border: .5px solid var(--accent-color);
  border-radius: 3px;
  padding: 70px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-shadow: 0 0 0 .2em var(--accent-color);
  position: relative;
}
.popular-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: var(--accent-color);
}
.tariff__item__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}
.tariff__item__header__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.tariff__item__header__price {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.tariff__item__header__price__currency {
  font-weight: bold;
  font-size: 18px;
  line-height: 21.4px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
.tariff__item__header__price__saleless {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  position: relative;
}
.tariff__item__header__price__saleless::before {
  content: '';
  position: absolute;
  width: 110%;
  left: -5%;
  top: 50%;
  border-bottom: 1px solid var(--primary-color);;
  transform: rotate(-3deg);
}
.tariff__item__button {
  padding: 17px;
  border: 1px solid var(--accent-color);
  border-radius: 3px;
  color: var(--accent-color);
  transition: all .2s ease;
}
.tariff__item__button:hover {
  background: var(--accent-color);
  color: #fff;
}
.tariff__item__button.popular {
  background: var(--accent-color);
  color: #fff;
}
.tariff__item__button.popular:hover {
  color: var(--accent-color);
  background: transparent;
}
.tariff__item__separator {
  border-bottom: 0.5px solid #00000033;
}
.tariff__item__text {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5)
}

.modal__header {
  display: flex;
  justify-content: flex-end;
}
.modal__header svg {
  cursor: pointer;
}
.modal__body {
  display: grid;
  row-gap: 10px;
}
.modal-enter-active,
.modal-leave-active {
  transition: all .3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
