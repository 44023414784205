<template>
  <div :class="'sidebar '+sidebarState">
    <div class="logo-details">
      <router-link to="/" class="logo-back">
        <SvgIcon class="back" name="chevron-left" :viewboxWidth="24" :viewboxHeight="40" :width="20" :height="20" />
        <span class="logo-name">Business</span>
      </router-link>
      <SvgIcon name="burger" class="toggle-menu" :viewboxWidth="40" @click="toggleSidebar" />
    </div>
    <nav>
      <ul class="nav-links">
        <li v-if="userIsSchoolAdmin" class="show-menu">
          <div class="link-dropdown">
            <router-link to="/business/users">
              <SvgIcon name="user" class="icon"/>
              <span class="link-name">Пользователи</span>
            </router-link>
            <SvgIcon
              name="chevron-down"
              :viewboxWidth="56"
              :viewboxHeight="56"
              class="arrow"
              @click="arrowStates.users = !arrowStates.users"
              :style="{transform: arrowStates.users ? 'rotate(180deg)' : 'rotate(0)'}"
            />
          </div>
          <Transition>
            <ul class="sub-menu" v-if="arrowStates.users  || sidebarState" v-collapse>
              <li><router-link class="link-name" to="/business/users">Пользователи</router-link></li>
              <li><router-link to="/business/users/students">Студенты</router-link></li>
              <li><router-link to="/business/users/teachers">Преподаватели</router-link></li>
            </ul>
          </Transition>
        </li>
        <li v-if="userIsSchoolAdmin" class="show-menu">
           <div class="link-dropdown">
            <router-link to="/business/requests">
              <SvgIcon name="requests" />
              <span class="link-name">Заявки</span>
            </router-link>
            <SvgIcon
              name="chevron-down"
              :viewboxWidth="56"
              :viewboxHeight="56"
              class="arrow"
              @click="arrowStates.requests = !arrowStates.requests"
              :style="{transform: arrowStates.requests ? 'rotate(180deg)' : 'rotate(0)'}"
            />
          </div>
          <Transition>
            <ul class="sub-menu" v-if="arrowStates.requests || sidebarState" v-collapse>
              <li><span class="link-name">Заявки</span></li>
              <li><router-link to="/business/requests/students">Студенты</router-link></li>
              <li><router-link to="/business/requests/teachers">Преподаватели</router-link></li>
            </ul>
          </Transition>
        </li>
        <li v-if="userIsSchoolAdmin">
          <router-link :to="user.data.role === 'superadmin' ? '/business/payments' : '/business/my-payments'">
            <SvgIcon name="payouts" />
            <span class="link-name">Вывод</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" :to="user.data.role === 'superadmin' ? '/business/payments' : '/business/my-payments'">Вывод</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/business/courses">
            <SvgIcon name="product" />
            <span class="link-name">Курсы</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/courses">Курсы</router-link></li>
          </ul>
        </li>
        <li>
          <router-link to="/business/groups">
            <SvgIcon name="groups" />
            <span class="link-name">Группы</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/groups">Группы</router-link></li>
          </ul>
        </li>

        <li>
          <router-link to="/business/schedule">
            <SvgIcon name="schedule" />
            <span class="link-name">Расписание</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/schedule">Расписание</router-link></li>
          </ul>
        </li>
        <li v-if="user.data.role !== 'superadmin'">
          <router-link to="/business/homeworks">
            <SvgIcon name="homework" />
            <span class="link-name">Домашние задание</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/homeworks">Домашние задание</router-link></li>
          </ul>
        </li>
        <li v-if="userIsSchoolAdmin">
          <router-link to="/business/schools">
            <SvgIcon name="school" />
            <span class="link-name">Мои школы</span>
            <!-- <span class="link-name">Моя школа</span> -->
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/schools">Мои школы</router-link></li>
          </ul>
        </li>
        <li v-if="user.data.role === 'superadmin'">
          <router-link to="/business/categories">
            <SvgIcon name="marketing" />
            <span class="link-name">Категории</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/categories">Категории</router-link></li>
          </ul>
        </li>
        <li v-if="user.data.role === 'superadmin'">
          <router-link to="/business/tags">
            <SvgIcon name="sales" />
            <span class="link-name">Тэги</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/tags">Тэги</router-link></li>
          </ul>
        </li>
        <!-- <li v-if="user.data.role === 'superadmin'">
          <router-link to="/business/grades">
            <SvgIcon name="grade" :viewboxHeight="56" :viewboxWidth="56" />
            <span class="link-name">Виды оценок</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><router-link class="link-name" to="/business/grades">Виды оценок</router-link></li>
          </ul>
        </li> -->
        <li>
          <button @click="showHelpModal">
            <SvgIcon name="help" />
            <span class="link-name">Помощь</span>
          </button>
          <ul class="sub-menu blank">
            <li><button class="link-name">Помощь</button></li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
  <div class="content">
    <Header />
    <slot />
  </div>
  <transition mode="out-in">
    <Modal v-if="helpModalIsActive" :hide="hideHelpModal">
      <div class="modal-header">
        <span class="title">Техническая поддержка</span>
      </div>
      <div class="modal-body">
        <div class="group">
          <span class="name">Наш электронный адрес</span>
          <a href="mailto:info@codetau.kz">info@codetau.kz</a>
        </div>
        <div class="group">
          <span class="name">Контактный номер</span>
          <a href="tel:+77082624586">+7 708 262 4586</a>
        </div>
        <div class="group">
          <span>Открыть чат:</span>
          <div class="links">
            <a class="socialNetwork whatsapp" href="https://api.whatsapp.com/send/?phone=%2B77082624586&text&type=phone_number&app_absent=0">
              <SvgIcon name="whatsapp" :viewboxWidth="40" :viewboxHeight="40" :width="40" :height="40" />
            </a>
            <a class="socialNetwork telegram" href="https://t.me/+77082624586">
              <SvgIcon name="telegram" :viewboxWidth="40" :viewboxHeight="40" :width="40" :height="40" />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  </transition>
</template>

<script setup>
import { computed, reactive, ref } from 'vue'
import Header from '../../uiBusiness/layouts/Header.vue'
import Modal from '@/components/common/Modal.vue'
import { useAuth } from '@/modules/auth'

const { user, selectedSchool } = useAuth()
const sidebarState = ref('')
const arrowStates = reactive({
  users: false,
  requests: false
})
const state = () => {
  if (localStorage.getItem('sidebar_state') == null) {
    localStorage.setItem('sidebar_state', false)
    return false
  } else if (localStorage.getItem('sidebar_state') === 'true') return true
  else if (localStorage.getItem('sidebar_state') === 'false') return false
}
const isActive = () => {
  if (state()) sidebarState.value = 'close'
  else sidebarState.value = ''
}

isActive()

const toggleSidebar = () => {
  if (localStorage.getItem('sidebar_state') === 'true') localStorage.setItem('sidebar_state', false)
  else localStorage.setItem('sidebar_state', true)
  isActive()
}

const helpModalIsActive = ref(false)

const showHelpModal = () => {
  helpModalIsActive.value = true
}
const hideHelpModal = () => {
  helpModalIsActive.value = false
}

const userIsSchoolAdmin = computed(() => {
  if (user.value.data.schools) {
    for (const school of user.value.data.schools) {
      if (school.id === Number(selectedSchool.value.id) && school.permission.role === 'admin') return true
    }
  }
  if (user.value.data.role === 'superadmin') return true
  return false
})
</script>

<style scoped>
a.router-link-active .link-name {
  color: #0082FA!important;
  opacity: 1;
}

a.router-link-active svg {
  fill: #0082FA!important;
}
.sidebar.close .router-link-active {
  background: #1d1b31;
  transition: all .2s ease;
  transition-delay: .5s;
}
.content {
  position: relative;
  left: 260px;
  transition: all .5s ease;
  max-width: calc( 100% - 260px);
  box-sizing: border-box;
  overflow: hidden;
  min-height: 100vh;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  background: #11101d;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.5s ease;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
nav {
  height: calc(100% - 60px);
}
.sidebar.close {
  width: 78px;
}
.sidebar.close ~ .content {
  left: 78px;
  transition: all .5s ease;
  max-width: calc( 100% - 78px);
  position: relative;
}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar.close .logo-name {
  display: none;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 26px;
}
.sidebar .logo-details .logo-back {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .logo-back:hover .back {
  opacity: 1;
  pointer-events: all;
}
.sidebar .logo-details .logo-back:hover .logo-name {
  transform: translateX(30px);
}
.sidebar .logo-details .logo-back .back {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: all .2s ease;
}
.sidebar .logo-details svg {
  fill: #fff;
  cursor: pointer;
}
.sidebar .logo-details .logo-name {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  border-bottom: 2px solid #fff;
  transition: all .2s ease;
}
.sidebar .nav-links {
  height: 100%;
  padding: 30px 0;
  overflow-x: hidden;
  overflow-y: overlay;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all .4s ease;
}
.sidebar .nav-links li:hover {
  background: #1d1b31;
}
.sidebar .nav-links li .link-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar .nav-links li a,
.sidebar .nav-links li button {
  display: flex;
  align-items: center;
  width: 100%;
}
.sidebar .nav-links li svg {
  min-width: 78px;
  fill: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li svg.arrow {
  min-width: 0;
  margin-right: 26px;
}
.sidebar .nav-links li.show-menu svg.arrow {
  transform: rotate(-180deg);
}
.sidebar .nav-links li .arrow {
  min-width: 0;
  margin-right: 26px;
}
.sidebar.close .nav-links li svg.arrow {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li a .link-name,
.sidebar .nav-links li button .link-name  {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  height: 70px;
  line-height: 70px;
  text-align: center;
  transition: all 0.4s ease;
  white-space: pre;
}
.sidebar .nav-links li .sub-menu a.router-link-active {
  opacity: 1;
}
.sidebar.close .nav-links li a .link-name,
.sidebar.close .nav-links li button .link-name {
  opacity: 0;
  pointer-events: none;
}
.sidebar.close .nav-links li .link-dropdown a {
  width: 78px;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.show-menu .sub-menu {
 display: block;
}
.sidebar .nav-links li .sub-menu a,
.sidebar .nav-links li .sub-menu button {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu .link-name {
  font-size: 18px;
  opacity: 1;
  display: block;
  color: #fff;
}
.sidebar .nav-links li .sub-menu .link-name {
  display: none;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 0;
  pointer-events: none;
  padding: 3px 20px 6px 16px;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

/* Модальное окно */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-header .title {
  font-size: 24px;
  font-weight: bold;
  color: #161931;
}
.modal-header .close-modal {
  cursor: pointer;
}
.modal-body {
  padding: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.modal-body .group {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.modal-body .group span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
}
.modal-body .group a {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--accent-color);
}
.modal-body .group .links{
  display: flex;
  grid-gap: 28px;
  padding-top: 10px;
}
.modal-body .group a.socialNetwork{
  display: block;
  height: 40px;
  width: 40px;
}
.modal-body .group a.socialNetwork svg{
  fill: #11101d;
  transition: fill .3s linear;
}
.modal-body .group a.socialNetwork.whatsapp:hover svg{
  fill: #63CE70;
}
.modal-body .group a.socialNetwork.telegram:hover svg{
  fill: #50A0D9;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.v-enter-active,
.v-leave-active {
  transition: all .3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
