<template>
  <header class="header">
    <div class="selected-school" v-click-outside="closeDropdown">
      <div v-if="user.data.schools">
        Школа: <b>{{ selectedSchool?.name }}</b>
        <span class="not-active" v-if="schoolIsOverdue()">Не активна</span>
        <span class="activate" v-if="schoolIsOverdue() && !isSuperAdmin()" @click="plansModalIsActive = true">Оплатить</span>
      </div>
      <button class="change-school" data-tooltip="Сменить школу" data-tooltip-location="bottom" @click="goSchools" v-if="(user.data.schools && user.data.schools.length > 1) || isSuperAdmin()">
        <SvgIcon name="settings" fill="black" :width="18" :height="18"/>
      </button>
      <button class="school-edit" data-tooltip="Редактировать школу" data-tooltip-location="bottom" @click="router.push(`/school/${selectedSchool?.id}/edit`)" v-if="user.data.schools">
        <SvgIcon
          name="pencil"
          :viewboxHeight="18"
          :viewboxWidth="18"
          :height="18"
          :width="18"
        />
      </button>
      <transition>
        <div class="dropdown" v-if="schoolsDropdownIsActive">
          <router-link class="business" v-wave to="/business/users" v-for="value of isSuperAdmin() ? allSchoolsList : getSchoolsList()" :key="value" @click="selectSchool(value)">{{ value.name }}</router-link>
        </div>
      </transition>
    </div>
    <ProfileDropdown />
    <Modal v-if="plansModalIsActive && !isSuperAdmin()" :hide="() => { plansModalIsActive = false }">
      <Plans :background="false"/>
    </Modal>
  </header>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Plans from '@/components/uiLanding/Plans.vue'
import ProfileDropdown from '@/components/Profile/ProfileDropdown.vue'

import { ref, watch } from 'vue-demi'
import { useRouter } from 'vue-router'

import api from '@/modules/api'
import { useAuth } from '@/modules/auth'

import filterSchool from '@/helpers/filterSchool.js'

const { user, selectedSchool } = useAuth()

const router = useRouter()

const systemsDropdownIsActive = ref(false)
const schoolsDropdownIsActive = ref(false)
const allSchoolsList = ref([])
const plansModalIsActive = ref(false)

const isSuperAdmin = () => user.value.data?.role === 'superadmin'

const getSchoolsList = () => {
  const filtered = filterSchool(user.value.data.schools)
  return filtered.filter(element => element.id !== parseInt(selectedSchool.value.id))
}

const loadSchools = () => {
  if (isSuperAdmin()) {
    api.requestGet(
      '/api/schools',
      {
        per_page: 10000
      },
      response => {
        allSchoolsList.value = response.data.data
      }
    )
  }
}
loadSchools()

watch(
  () => user.value,
  () => loadSchools()
)

// const goToSytem = () => {
//   systemsDropdownIsActive.value = true
// }
const goSchools = () => {
  systemsDropdownIsActive.value = false
  schoolsDropdownIsActive.value = true
}
// const resetDropdown = () => {
//   systemsDropdownIsActive.value = false
//   schoolsDropdownIsActive.value = false
// }

const selectSchool = (item) => {
  selectedSchool.value = item
  router.go()
}

const schoolIsOverdue = () => new Date(selectedSchool.value.active_before) < new Date()

if (schoolIsOverdue()) plansModalIsActive.value = true

const closeDropdown = () => {
  schoolsDropdownIsActive.value = false
}
</script>

<style scoped>
.header {
  height: 70px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.selected-school {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  position: relative;
}
.not-active {
  margin-left: 10px;
  background: var(--error-color);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
}
.activate {
  margin-left: 10px;
  color: var(--accent-color);
  font-size: 12px;
  cursor: pointer;
}
.activate:hover {
  text-decoration: underline;
}
.change-school {
  cursor: pointer;
}
.selected-school .dropdown {
  display: flex;
  position: absolute;
  transition: all .3s ease;
  flex-direction: column;
  top: 20px;
  left: 0;
  background: #fff;
  border-radius: 5px;
  border: 1px solid var(--accent-color);
  width: max-content;
  max-height: 400px;
  overflow: auto;
  z-index: 10;
}
.selected-school .dropdown a {
  padding: 15px 20px;
  color: var(--primary-color);
  font-size: 15px;
  text-align: start;
}
.selected-school .dropdown a:hover {
  background: var(--hover-color);
}
.drop-enter-active,
.drop-leave-active {
  transition: all .3s ease;
}
.drop-enter-from,
.drop-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all .3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
