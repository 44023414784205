<template>
  <Sidebar>
    <router-view v-slot="{ Component }">
      <transition mode="out-in">
        <!-- <keep-alive> -->
          <component :is="Component" :key="$route.path" />
        <!-- </keep-alive> -->
      </transition>
    </router-view>
    <transition mode="out-in">
      <Modal v-if="reminderModalIsActive"
      :hide="hideReminderModal"
      >
        <div class="reminder-modal">
          <div class="reminder-text">
            <h3>Напоминание</h3>
            <span>Через {{day(leftTariffEndDay)}} у вас закончится тариф!</span>
          </div>
          <div class="buttons">
            <router-link to="/for-business#plans">
              <Button name="продлить"/>
            </router-link>
            <Button name="скрыть" type="cancel" @click="hideReminderModal"/>
          </div>
        </div>
      </Modal>
    </transition>
  </Sidebar>
</template>

<script setup>
import Sidebar from '@/components/uiBusiness/layouts/Sidebar.vue'
import Modal from '@/components/common/Modal.vue'
import moment from 'moment'
import { ref } from 'vue'
import { useAuth } from '@/modules/auth'

const { selectedSchool } = useAuth()
const reminderModalIsActive = ref(false)

const nowDate = moment()
const activeBefore = selectedSchool.value?.active_before ? moment(selectedSchool.value.active_before) : moment()
const leftTariffEndDay = +activeBefore.diff(nowDate, 'days')

if (leftTariffEndDay <= 7 && leftTariffEndDay > 0) {
  reminderModalIsActive.value = true
}

const day = (dayNum) => {
  if (dayNum < 5 && dayNum > 1) {
    return dayNum + ' дня'
  } else if (dayNum === 1) {
    return dayNum + ' день'
  } else {
    return dayNum + ' дней'
  }
}

const hideReminderModal = () => {
  reminderModalIsActive.value = false
}
</script>

<style scoped>

.reminder-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px
}
.reminder-text h3{
  font-size: 22px;
}
.reminder-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}
.reminder-modal .buttons {
  display: flex;
  gap: 20px;
}

.v-leave-active,
.v-enter-active {
  transition: all .3s ease;
}

.v-enter-from {
  opacity: 0;
  transform: translateY(2%);
}
.v-leave-to {
  opacity: 0;
  transform: scale(99%) translateY(1%);
}
</style>
