<template>
  <modal :hide="hide">
    <div class="header">
      <span class="title">Оплата тарифа</span>
    </div>
    <div class="body">
      <span v-if="user?.data?.schools.length == 1">Школа: <strong>{{ schoolsList()[0].name }}</strong></span>
      <div v-else class="select-school">
        <span>Выберите школу</span>
        <select name="select_school">
          <option value="" selected disabled>Выберите школу</option>
          <option v-for="school of schoolsList()" :key="school" :value="school.id">{{ school.name }}</option>
        </select>
      </div>
      <span class="selected-tariff">Тариф: <strong>{{ tariffData.name }}</strong></span>
      <span class="selected-tariff">Цена: <strong>{{ tariffData.price }} {{ tariffData.currency }}</strong></span>
      <Button name="Оплатить" v-wave @click="getOrderId" :loading="loadingState" size="large"/>
    </div>
  </modal>
</template>

<script setup>
import { ref } from 'vue-demi'
import Modal from '@/components/common/Modal.vue'
import { useAuth } from '@/modules/auth'
import api, { appUrl } from '@/modules/api'

const props = defineProps({
  hide: {
    type: Function,
    default: null
  },
  tariff_id: {
    type: Number,
    required: true
  }
})
const { user } = useAuth()
// const addCardIsActive = ref(false)
const loadingState = ref(false)
const tariffData = ref({
  name: 'Загрузка...',
  price: 'Загрузка...'
})

const schoolsList = () => {
  return user.value.data.schools.filter(school => school.permission.role === 'admin')
}

api.requestGet(
  `/api/subscriptions/${props.tariff_id}`,
  {},
  response => {
    tariffData.value = response.data.data
  }
)

const getOrderId = () => {
  if (!loadingState.value) {
    loadingState.value = true
    api.requestPost(
      '/api/orders',
      {
        subscription_id: props.tariff_id,
        payable_id: schoolsList()[0].id
      },
      response => {
        api.requestPost(
          '/api/payments/paybox/init-payment',
          {
            order_id: response.data.data.id,
            success_url: `${appUrl}/business-plans/success`,
            failure_url: `${appUrl}/business-plans/failure`
          },
          response => {
            window.location.href = response.data.data.redirect_url
          }
        )
      }
    )
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.header .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  margin-bottom: 25px;
}
.header svg {
  cursor: pointer;
}

.school-not-selected-error {
  color: var(--error-color);
  text-align: center;
  font-size: 30px;
}
.body {
  display: grid;
  row-gap: 10px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 3px;
}
form h2 {
  text-align: center;
}
.group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.group .name {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color);
}
input {
  padding: 17px;
}
.validity-cvv-cvc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
}
.validity-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}
.validity-inputs input {
  max-width: 100px;
}
input.cvv-cvc {
  max-width: 100px;
}
input[type="submit"] {
  background: var(--accent-color);
  border: none;
  color: #fff;
}

.select-school {
  display: flex;
  align-items: center;
  gap: 10px;
}
select[name="select_school"] {
  padding: 16px;
}
.go-to-payment {
  background: var(--accent-color);
  color: #fff;
  padding: 12px 17px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.go-to-payment span {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  line-height: 24px;
}
.disabled {
  pointer-events: none;
}
</style>
